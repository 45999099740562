@import "tailwindcss/components";
@import "tailwindcss/base";
@import "tailwindcss/utilities";

@font-face {
    font-family: "CADT";
    src: local("CADT"),
     url("./fonts/CADTMonoDisplay-Regular.ttf") format("truetype");
    }
@font-face {
        font-family: "Comfortaa";
        src: local("Comfortaa"),
         url("./fonts/Comfortaa-Regular.ttf") format("truetype");
        }
        @font-face {
            font-family: "Kh-Pixel";
            src: local("Kh-Pixel"),
             url("./fonts/kh_pixel_retro_8-bit_text.ttf") format("truetype");
            }